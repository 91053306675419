import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Link, Button } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useTroveView } from "./context/TroveViewContext";
import { Icon } from '../Icon';


export const NoTrove: React.FC = props => {
  const { dispatchEvent } = useTroveView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>Trove</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You haven't borrowed any LUSD yet.">
          You can borrow LUSD by opening a Trove.
          <br></br>
          <br></br>
          <Link href="https://docs.liquity.org/faq/borrowing#what-is-a-trove" target="_blank" title="Learn More"><Icon name="exclamation-triangle" /> Learn More</Link>


</InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleOpenTrove}>Open Trove</Button>
        </Flex>
      </Box>
    </Card>
  );
};
