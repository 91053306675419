import React, { useState, useRef } from "react";
import { Box, Button, NavLink, Container, Flex } from "theme-ui";
import { Icon } from "./Icon";
import { LiquityLogo } from "./LiquityLogo";

const logoHeight = "32px";

export const SideNav: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);

  if (!isVisible) {
    return (
      <Button sx={{ display: ["flex", "none"] }} variant="icon" onClick={() => setIsVisible(true)}>
        <Icon name="bars" size="lg" />
      </Button>
    );
  }
  return (
    <Container
      variant="infoOverlay"
      ref={overlay}
      onClick={e => {
        if (e.target === overlay.current) {
          setIsVisible(false);
        }
      }}
    >
      <Flex variant="layout.sidenav">
        <Button
          sx={{ position: "fixed", right: "25vw", m: 2 }}
          variant="icon"
          onClick={() => setIsVisible(false)}
        >
          <Icon name="times" size="2x" />
        </Button>
        <LiquityLogo height={logoHeight} p={2} />
        <Box as="nav" sx={{ m: 3, mt: 1, p: 0 }} onClick={() => setIsVisible(false)}>
          
          <NavLink href="/">Dashboard</NavLink>
        <NavLink href="#/bonds">Bonds</NavLink>
        <NavLink href="https://curve.fi/#/ethereum/swap" target="_blank">Buy LUSD</NavLink>

        <NavLink href="https://docs.chickenbonds.org/" target="_blank">Docs</NavLink>
        <NavLink href="https://dune.com/chickenbonds/lusd" target="_blank">Stats</NavLink>
        <NavLink href="https://looksrare.org/collections/0xa8384862219188a8f03c144953Cf21fc124029Ee" target="_blank">NFTs</NavLink>
        <NavLink sx={{ fontSize: 1 }} href="#/risky-troves">
        ≐ Risky Troves
        </NavLink>
        </Box>
      </Flex>
    </Container>
  );
};
