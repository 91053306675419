import { Flex, Box, NavLink } from "theme-ui";

//import { Link } from "./Link";


export const Nav: React.FC = () => {
  return (
    <Box as="nav" sx={{ display: ["none", "flex"], alignItems: "center", flex: 1 }}>
      <Flex>
        <NavLink href="/">Dashboard</NavLink>
        <NavLink href="#/bonds">Bonds</NavLink>

        <NavLink href="https://curve.fi/#/ethereum/swap" target="_blank">Buy LUSD</NavLink>
        <NavLink href="https://docs.chickenbonds.org/" target="_blank">Docs</NavLink>
        <NavLink href="https://dune.com/chickenbonds/lusd" target="_blank">Stats</NavLink>
        <NavLink href="https://looksrare.org/collections/0xa8384862219188a8f03c144953Cf21fc124029Ee" target="_blank">NFTs</NavLink>
      </Flex>

      <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        <NavLink sx={{ fontSize: 1 }} href="#/risky-troves">
        ≐ Risky Troves
        </NavLink>
      </Flex>

    </Box>
  );
};
