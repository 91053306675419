import React, { useState, useEffect } from "react";
import { Flex, Link, Grid, Image, Spinner, Box } from "theme-ui";

// import { LiquityStoreState } from "@liquity/lib-base";
// import { useLiquitySelector } from "@liquity/lib-react";

import CoinGecko from 'coingecko-api';

import { Icon } from './Icon';
// import { Decimal } from "@liquity/lib-base";


// const selectPrice = ({ price }: LiquityStoreState) => price;

export const PriceManager: React.FC = () => {


  const [loading, setLoading] = useState(true);

  const defaultPrices: { [key: string]: { [key: string]: string } } = {}
  const [coinGeckoPrices, setCoinGeckoPrices] = useState(defaultPrices);

  const CoinGeckoClient = new CoinGecko();

  

  useEffect(() => {
    let timer: any;
    async function fetchPrices() {
      console.log('Fetching new prices...')
      try {
        let res = await CoinGeckoClient.simple.price({
          ids: ['ethereum', 'liquity', 'boosted-lusd', 'liquity-usd'],
          vs_currencies: ['usd'],
        });
        if (res.success) {
          setCoinGeckoPrices(res.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(`Error fetching prices!`, e)
      }
      if (timer) clearTimeout(timer);
      timer = setTimeout(fetchPrices, 15000);
    }
    fetchPrices();
    return () => timer && clearTimeout(timer);


    // For some reason, adding CoinGeckoClient.simple as a dependency creates serious issues, so ignore that
    // eslint-disable-next-line
  }, [])



  return (
      <Flex sx={{ alignItems: "center", justifyContent: "center", textAlign: 'center' }}>
      {loading ? (
        <>
        <Spinner sx={{ m: 2, color: "text" }} size="32px" />
        <span>Loading prices...</span>
        </>
      ) : (
        <>
<Grid gap={3} columns={[2, null, 4]}>
            <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
              <Image src="/icons/128-lusd-icon.svg" sx={{height: '32px'}} />
              <div>
              <strong>LUSD </strong>

              </div>
              <div>
                ${parseFloat(coinGeckoPrices['liquity-usd'].usd).toFixed(2)}
              </div>
              <div>
              <Link href="https://www.coingecko.com/en/coins/liquity-usd" target="_blank" title="CoinGecko Information"><Icon name="chart-line" /></Link>
                <Link href="https://etherscan.io/token/0x5f98805a4e8be255a32880fdec7f6728c6568ba0" target="_blank" title="Contract Address"><Icon name="cog" /></Link>
                <Link href="https://curve.fi/#/ethereum/swap" target="_blank" title="Swap on Curve"><Icon name="bolt" /></Link>
              </div>
            </Box>

            <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
              <Image src="/icons/eth.svg" sx={{height: '32px'}} />
              <div>
                <strong>ETH </strong>  
              </div>
              <div>
                ${coinGeckoPrices['ethereum'].usd}
              </div>
              <div>
              <Link href="https://www.coingecko.com/en/coins/ethereum" target="_blank" title="CoinGecko Information"><Icon name="chart-line" /></Link>
              <Link href="https://data.chain.link/eth-usd" target="_blank" title="Chainlink ETH Price Feed"><Icon name="cog" /></Link>
              </div>
            </Box>

            <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
              <Image src="/icons/128-lqty-icon.svg" sx={{height: '32px'}} />
              <div>
                <strong>LQTY </strong>  
              </div>
              <div>
                ${coinGeckoPrices['liquity'].usd}
              </div>
              <div>
                </div>
              <div>
                <Link href="https://www.coingecko.com/en/coins/liquity" target="_blank" title="CoinGecko Information"><Icon name="chart-line" /></Link>
                <Link href="https://etherscan.io/token/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d" target="_blank" title="Contract Address"><Icon name="cog" /></Link>
                <Link href="https://app.uniswap.org/#/swap?outputCurrency=0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d" target="_blank" title="Swap on Uniswap"><Icon name="bolt" /></Link>
              </div>
            </Box>

            <Box style={{flexGrow:1, flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}} my={3}>
              <Image src="/icons/bLUSD_logo.png" sx={{height: '32px'}} />
              <div>
              <strong>bLUSD </strong>

              </div>
              <div>
              ${coinGeckoPrices['boosted-lusd'].usd}
              </div>
              <div>
              <Link href="https://www.coingecko.com/en/coins/boosted-lusd" target="_blank" title="Coin Gecko Information"><Icon name="chart-line" /></Link>
                <Link href="https://etherscan.io/token/0xb9d7dddca9a4ac480991865efef82e01273f79c3" target="_blank" title="Contract Address"><Icon name="cog" /></Link>
                <Link href="https://app.uniswap.org/#/swap" target="_blank" title="Swap on Uniswap"><Icon name="bolt" /></Link>
              </div>
            </Box>
            </Grid>

        </>
      )}
      </Flex>
  );
};

